export default function IconcCpChair() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M8.09033 4V16.9636" stroke="black" strokeLinecap="round" />
            <path d="M23.9814 4V16.9636" stroke="black" strokeLinecap="round" />
            <path d="M8.09033 5.67273C10.2634 6.81759 16.484 8.51854 23.9812 6.16339" stroke="black" strokeLinecap="round" />
            <path d="M8.09033 11.5272C10.2634 12.6721 16.484 14.373 23.9812 12.0179" stroke="black" strokeLinecap="round" />
            <path
                d="M6.83588 27L25.4327 17.2973C25.5356 17.2436 25.4788 17.0824 25.3646 17.104C18.0546 18.482 10.3397 17.7537 6.70711 17.1003C6.59282 17.0797 6.53612 17.2436 6.63907 17.2973L25.2359 27"
                stroke="black"
                strokeLinecap="round"
            />
            <rect x="0.44" y="0.44" width="31.12" height="31.12" rx="3.56" stroke="black" strokeWidth="0.88" />
        </svg>
    );
}
